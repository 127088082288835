import styles from "./Hero.module.less";
import clsx from "clsx";

export const HeroParagraph = ({ className, type, children }) => {
  return (
    <p
      className={clsx(
        styles.HeroParagraph,
        type === "subheading" && styles.HeroSubheading,
        className,
      )}
    >
      {children}
    </p>
  );
};
