import styles from "./Section.module.less";
import clsx from "clsx";

export const SectionList = ({ className, children, size = "md", background }) => {
  const backgroundClassName = background?.replace(/-/g, "_").toLowerCase();
  return (
    <ul
      className={clsx(
        styles.SectionList,
        size === "sm" && styles.SectionListSmall,
        background && styles.SectionListCard,
        background && styles[backgroundClassName],
        className,
      )}
    >
      {children}
    </ul>
  );
};
