import clsx from "clsx";
import styles from "./Hero.module.less";

export const Hero = ({
  id,
  className,
  background = "violet-900",
  type = "one-col",
  doubledPadding = false,
  removeTopPadding,
  removeBottomPadding,
  withStarsAnimation = false,
  children,
}) => {
  const backgroundClassName =
    typeof background === "string"
      ? background.replace(/-/g, "_").toLowerCase()
      : typeof background === "object"
      ? background.map(bg => bg.replace(/-/g, "_").toLowerCase()).join("_to_")
      : null;

  return (
    <section
      className={clsx(
        styles.Hero,
        styles[backgroundClassName],
        (type === "two-col" || type === "two-col-form") && styles.TypeTwoCol,
        type === "two-col-form" && styles.TypeTwoColForm,
        type === "two-col-text" && styles.TypeTwoColText,
        doubledPadding && styles.DoubledPadding,
        removeTopPadding && styles.RemoveTopPadding,
        removeBottomPadding && styles.RemoveBottomPadding,
        className,
      )}
      id={id}
    >
      {withStarsAnimation && (
        <div className={styles.HeroStars}>
          <div className={styles.HeroStars1} />
          <div className={styles.HeroStars2} />
          <div className={styles.HeroStars3} />
        </div>
      )}
      <div className={styles.HeroContainer}>
        <div className={styles.HeroColWrapper}>{children}</div>
      </div>
    </section>
  );
};
