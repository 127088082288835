import clsx from "clsx";
import styles from "./Section.module.less";
import { SectionHeading, SectionParagraph } from "../Section";

const DataItem = ({ name, text }) => {
  return (
    <div className={clsx(styles.SectionButtonGridButton)}>
      <div className={styles.SectionButtonGridContent}>
        {name && <SectionHeading>{name}</SectionHeading>}
        {text && <SectionParagraph>{text}</SectionParagraph>}
      </div>
    </div>
  );
};

export const SectionDataGrid = ({ data, gridSize = 8, className }) => {
  if (!data) return null;
  return (
    <div className={styles.SectionButtonGridWrapper}>
      <div
        className={clsx(
          styles.SectionButtonGrid,
          gridSize === 4 && styles.GridSizeFour,
          gridSize === 2 && styles.GridSizeTwo,
          className,
        )}
      >
        {data?.map((b, i) => (
          <DataItem key={i} name={b.name} text={b.text} />
        ))}
      </div>
    </div>
  );
};
