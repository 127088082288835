import styles from "./Hero.module.less";
import clsx from "clsx";
import { useState, useRef } from "react";
import { FiPlay } from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";
import { animatePresenceConfig } from "../../../config/appConfig";
import { sendGaEvent } from "../../../config/userTracking";
import Image from "next/image";
import { useViewport } from "../../../platform/utils/useViewport";

export const HeroVideo = ({ src, image, mobileImage, className }) => {
  const [videoMounted, setVideoMounted] = useState(false);
  const ref = useRef(null);
  const { desktop, mobile } = useViewport();

  const deviceSpecificProps = {
    ...(desktop && {
      priority: true,
      src: image,
    }),
    ...(mobile &&
      mobileImage && {
        src: mobileImage,
      }),
  };

  const mountVideo = () => {
    sendGaEvent({
      action: "Click to watch video",
      category: "Video",
      label: "Watch homepage video",
    });
    ref.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    setVideoMounted(true);
  };

  /* eslint-disable jsx-a11y/media-has-caption */
  return (
    <div ref={ref} className={clsx(styles.HeroVideo, className)}>
      {!videoMounted && (
        <div className={styles.HeroVideoPlaceholder}>
          <div className={styles.HeroVideoPlaceholderImage}>
            <Image
              width="958"
              height="534"
              src={image}
              layout="responsive"
              priority={false}
              {...deviceSpecificProps}
              alt=""
            />
          </div>
          <button aria-label="Play video" onClick={mountVideo}>
            <FiPlay />
            <span>Play Video</span>
          </button>
        </div>
      )}
      {src && videoMounted && (
        <AnimatePresence>
          <motion.div {...animatePresenceConfig}>
            <video
              autoPlay
              className={styles.HeroVideoPlayer}
              src={src}
              controls
              controlsList="nodownload noremoteplayback"
            ></video>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};
