import clsx from "clsx";
import styles from "./Button.module.less";

import Link from "next/link";
import Demyst from "../../views/assets/Demyst.svg";

export const LinkButton = ({
  autoWidth = false,
  demystIcon = false,
  hidden,
  isExternal,
  onClick,
  variant = "primary",
  size = "lg",
  disabled,
  to,
  children,
  className,
  noStyle = false,
  bookDemoButtonLandingPage = false,
  ...props
}) => {
  const classesArray = [
    "Button",
    styles.LinkButton,
    variant === "inverted" && styles.Inverted,
    variant === "text" && styles.Text,
    variant === "secondary" && styles.Secondary,
    variant === "secondary-alt" && styles.Secondary_alt,
    hidden && styles.Hidden,
    autoWidth === true && styles.AutoWidth,
    size === "hero" && styles.Size_lg,
    size === "lg" && styles.Size_lg,
    size === "md" && styles.Size_md,
    size === "sm" && styles.Size_sm,
    disabled && styles.Disabled,
    bookDemoButtonLandingPage && styles.BookDemoButton,
    className,
  ];

  const classes = noStyle !== true ? clsx(classesArray) : className;

  return (
    <Link
      href={to}
      className={classes}
      onClick={e => {
        onClick && onClick(e);
      }}
      isExternal={isExternal}
      {...props}
    >
      {noStyle !== true ? <span>{children}</span> : children}
      {demystIcon && <img src={Demyst.src} alt="" width="16" height="22" loading="eager" />}
    </Link>
  );
};
