import { useState, useEffect } from "react";
/**
 * This hook is a kind of React wrapper over `window.matchMedia` functionality. It receives media
 * query as a parameter and determines if the document matches that query. It also monitors changes
 * in document so it will change the returned value if needed.
 * @param query
 * @returns {boolean}
 */
export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);
  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    // older browsers don't have support for`addEventListener` in matchMedia and have to use
    // older "addListener" function
    if (media && media.addEventListener) {
      media.addEventListener("change", listener);
    } else {
      media.addListener(listener);
    }
    return () => {
      if (media && media.removeEventListener) {
        media.removeEventListener("change", listener);
      } else {
        media.removeListener(listener);
      }
    };
  }, [matches, query]);
  return matches;
}
