import Image from "next/image";
import styles from "./Section.module.less";
import { useViewport } from "../../../platform/utils/useViewport";
import clsx from "clsx";

export const SectionImage = ({
  src,
  alt = "",
  className,
  tabletSrc,
  mobileSrc,
  layout,
  ...props
}) => {
  const { desktop, tablet, mobile } = useViewport();

  const deviceSpecificProps = {
    ...(desktop && {
      priority: true,
      src: src,
    }),
    ...(tablet &&
      tabletSrc && {
        src: tabletSrc,
      }),
    ...(mobile &&
      mobileSrc && {
        src: mobileSrc,
      }),
  };

  return (
    <div
      className={clsx(styles.SectionImage, layout === "responsive" && styles.Responsive, className)}
    >
      <Image
        src={src}
        alt={alt}
        layout={layout}
        priority={false}
        {...deviceSpecificProps}
        {...props}
      />
    </div>
  );
};
