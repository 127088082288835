import { useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./SectionTestimonials.module.less";
import Image from "next/image";
import { SectionParagraph, SectionHeading } from "../Section";

import TestimonialImg from "../../views/assets/images/Testimonial.png";
import Testimonial1 from "../../views/assets/images/Testimonial1.png";
import Testimonial2 from "../../views/assets/images/Testimonial2.png";
import Testimonial3 from "../../views/assets/images/Testimonial3.png";
import Testimonial4 from "../../views/assets/images/Testimonial4.png";

import MomentLogo1 from "../../views/assets/images/momnt-logo-1.png";
import KoalafiLogo3 from "../../views/assets/images/koalafi-logo-1.png";

import { FiArrowLeft, FiArrowRight, FiStar } from "react-icons/fi";
import { useViewport } from "../../../platform/utils/useViewport";

const testimonials = [
  [
    {
      quote:
        "We wouldn't have been as good at what we are doing had we not had a partner like Demyst that can flip on data, data partners and data channels effectively overnight, and advise us on the other data channels and data partners we should be looking at for our benefit. Time to market with a partner like Demyst was and is crucial for our success, especially our early success.",
      role: "Chief Technology Officer",
      source: "Brian Lanehart",
      color: "coolGray-200",
      image: MomentLogo1,
    },
    {
      quote:
        "Leveraging a single API to maintain external data relationships has simplified and accelerated our fraud prevention and credit processes supporting business growth into new geographies.",
      role: "Director of Engineering",
      source: "Leading Global Payments and Shopping Service",
      color: "blue-300",
      image: TestimonialImg,
    },
    {
      quote:
        "There's a lot of simplicity to doing a one-time integration versus a bunch of independent integrations.",
      role: "Senior Vice President of Fraud",
      source: "David Hicks",
      color: "coolGray-100",
      image: KoalafiLogo3,
    },
  ],
  [
    {
      quote:
        "As a startup, there's no way we could've gotten to market with a beta product in six months, without someone like Demyst.",
      role: "Chief Technology Officer",
      source: "Brian Lanehart",
      color: "blue-200",
      image: MomentLogo1,
    },
    {
      quote:
        "Given the complexity and the number of teams involved and integrations this implementation has gone extremely well, which is a testament to the testing planning and preparation.",
      role: "Senior Program Manager",
      source: "Tier 1 Australian Bank",
      color: "coolGray-200",
      image: Testimonial2,
    },
    {
      quote: "You’ve exceeded my expectations.",
      role: "Head of Risk",
      source: "Leading Asia Pacific cashback platform",
      color: "blue-300",
      image: Testimonial3,
    },
  ],
  [
    {
      quote:
        "It's also important in a partner like Demyst because they know the industry because they understand your business and your use case.",
      role: "Senior Vice President of Fraud",
      source: "David Hicks",
      color: "coolGray-100",
      image: KoalafiLogo3,
    },
    {
      quote:
        "This sophisticated new technology is deeply integrated with client's existing platforms to provide our customers with a quick, simple and secure experience.",
      role: "Group Executive",
      source: "Tier 1 Australian Bank",
      color: "blue-200",
      image: Testimonial4,
    },
    {
      quote:
        "Thank you for a great year and getting our project live. Appreciate your patience, hard work as well as resilience in getting us to a very good position ahead of our pilot activation.",
      role: "Senior Delivery Manager",
      source: "Tier 1 Australian Bank",
      color: "coolGray-100",
      image: Testimonial1,
    },
  ],
];

export const SectionTestimonials = ({ duration = 10000 }) => {
  // it's all hardcoded here because why not
  const [index, setIndex] = useState(0);
  const viewport = useViewport();

  const getNextIndex = currentIndex => {
    const index = currentIndex + 1;
    return index < testimonials.length ? index : 0;
  };

  const getPrevIndex = currentIndex => {
    const index = currentIndex - 1;
    return index >= 0 ? index : testimonials.length - 1;
  };

  useEffect(() => {
    if (viewport.desktop_small_min) {
      const timeout = setTimeout(() => {
        setIndex(getNextIndex(index));
      }, duration);

      return () => clearTimeout(timeout);
    }
  }, [index]);

  return (
    <>
      <div className={styles.Container}>
        <div className={clsx(styles.Wrapper)}>
          {testimonials[index].map(({ quote, role, source, image }) => (
            <div className={styles.Card} key={quote}>
              <div className={styles.Content}>
                <div className={styles.Stars}>
                  <FiStar />
                  <FiStar />
                  <FiStar />
                  <FiStar />
                  <FiStar />
                </div>
                <SectionHeading type="h4" asParagraph className={styles.Quote}>
                  “{quote}”
                </SectionHeading>
                <div className={styles.Attribution}>
                  <div className={styles.ImageWrapper}>
                    <div className={styles.Image}>
                      <Image
                        src={image}
                        alt=""
                        height="64"
                        width="64"
                        layout="responsive"
                        priority={false}
                      />
                    </div>
                  </div>
                  <div className={styles.Author}>
                    <SectionParagraph className={styles.Role}>{role}</SectionParagraph>
                    <SectionParagraph className={styles.Source}>{source}</SectionParagraph>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {viewport.desktop_small_min && (
        <div className={styles.Arrows}>
          <button
            className={styles.Arrow}
            onClick={() => setIndex(getPrevIndex(index))}
            aria-label="Go to previous testimonial"
          >
            <FiArrowLeft />
          </button>
          <button
            className={styles.Arrow}
            onClick={() => setIndex(getNextIndex(index))}
            aria-label="Go to next testimonial"
          >
            <FiArrowRight />
          </button>
        </div>
      )}
    </>
  );
};
