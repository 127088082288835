import { LinkButton } from "./LinkButton";
import { sendGaEvent } from "../../../config/userTracking";
import { linksConstants } from "../../../constants/linksConstants";
import styles from "./Button.module.less";

export const CtaLinkButton = ({ className, autoWidth = true, text, classNameDiv, ...props }) => {
  const sendCtaGaEvent = () => {
    sendGaEvent({
      action: `CTA Click - Request A Demo`,
      category: "Main CTA",
      label: `/app/contact`,
    });
  };

  return (
    <div className={`${styles.CtaLinkButtonContainer} ${classNameDiv}`}>
      <LinkButton
        demystIcon
        autoWidth={autoWidth}
        variant="secondary"
        onClick={sendCtaGaEvent}
        href={linksConstants.REQUEST_DEMO}
        className={className}
        {...props}
      >
        {text || "Request a demo"}
      </LinkButton>
    </div>
  );
};
