import styles from "./Section.module.less";
import clsx from "clsx";
import { FiCheck } from "react-icons/fi";

export const SectionListItem = ({ icon, className, children }) => {
  const iconToShow = icon ? (
    typeof icon === "function" ? (
      icon()
    ) : typeof icon === "string" ? (
      <img src={icon} alt="" />
    ) : null
  ) : typeof icon === "object" ? (
    icon
  ) : (
    <FiCheck />
  );
  return (
    <li className={clsx(styles.SectionListItem, className)}>
      <div className={styles.SectionListItemIcon}>{iconToShow}</div>
      <div className={styles.SectionListItemContent}>{children}</div>
    </li>
  );
};
