import styles from "./Section.module.less";
import clsx from "clsx";

export const SectionParagraph = ({ className, type, children }) => {
  return (
    <p
      className={clsx(
        styles.SectionParagraph,
        type === "subheading" && styles.SectionSubheading,
        className,
      )}
    >
      {children}
    </p>
  );
};
