import clsx from "clsx";
import styles from "./Section.module.less";
import Image from "next/image";
import { SectionHeading, SectionParagraph } from "../Section";

import { LinkButton, Button as Btn } from "../Button";

const Button = ({ icon, name, text, href, color, type }) => {
  const colorClassName = color?.replace(/-/g, "_").toLowerCase();
  const nameClassName = name?.replace(/ /g, "");

  if (!href)
    return (
      <Btn
        className={clsx(
          styles.SectionButtonGridButton,
          styles[nameClassName],
          styles[colorClassName],
        )}
        noStyle
      >
        <span>
          <div className={styles.SectionButtonGridButtonIcon}>
            <Image
              src={icon}
              alt={name}
              width="64"
              height="64"
              layout="responsive"
              priority={false}
            />
          </div>
          {type === "use-cases" ? (
            <div className={styles.SectionButtonGridContent}>
              {name && <SectionHeading>{name}</SectionHeading>}
              {text && <SectionParagraph>{text}</SectionParagraph>}
            </div>
          ) : type === "data-providers" || type === "pages" ? (
            <>
              {name && <SectionHeading>{name}</SectionHeading>}
              {text && <SectionParagraph>{text}</SectionParagraph>}
              {href && (
                <span className={styles.SectionButtonGridButtonLabel}>
                  {type === "data-providers" ? "View In Catalog" : "Learn More"}
                </span>
              )}
            </>
          ) : null}
        </span>
      </Btn>
    );

  return (
    <LinkButton
      className={clsx(
        styles.SectionButtonGridButton,
        styles[nameClassName],
        styles[colorClassName],
      )}
      href={href}
      noStyle
    >
      <span>
        <div className={styles.SectionButtonGridButtonIcon}>
          <Image
            src={icon}
            alt={name}
            width="64"
            height="64"
            layout="responsive"
            priority={false}
          />
        </div>
        {type === "use-cases" ? (
          <div className={styles.SectionButtonGridContent}>
            {name && <SectionHeading>{name}</SectionHeading>}
            {text && <SectionParagraph>{text}</SectionParagraph>}
          </div>
        ) : type === "data-providers" || type === "pages" ? (
          <>
            {name && <SectionHeading>{name}</SectionHeading>}
            {text && <SectionParagraph>{text}</SectionParagraph>}
            {href && (
              <span className={styles.SectionButtonGridButtonLabel}>
                {type === "data-providers" ? "View In Catalog" : "Learn More"}
              </span>
            )}
          </>
        ) : null}
      </span>
    </LinkButton>
  );
};

export const SectionButtonGrid = ({
  buttons,
  gridSize = 8,
  type = "data-providers",
  color = "white",
  className,
}) => {
  if (!buttons) return null;
  return (
    <div className={styles.SectionButtonGridWrapper}>
      <div
        className={clsx(
          styles.SectionButtonGrid,
          gridSize === 4 && styles.GridSizeFour,
          gridSize === 2 && styles.GridSizeTwo,
          type === "use-cases" && styles.UseCases,
          type === "data-providers-small" && styles.DataProviders,
          className,
        )}
      >
        {buttons?.map((b, i) => (
          <Button
            key={i}
            icon={b.icon}
            name={b.name}
            href={b.href ? b.href : null}
            text={b.text}
            color={color}
            type={type}
          />
        ))}
      </div>
    </div>
  );
};
