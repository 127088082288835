import { useMediaQuery } from "./useMediaQuery";

export const useViewport = () => {
  const desktop_small_min = useMediaQuery("(min-width: 64.001rem)");
  const desktop_min = useMediaQuery("(min-width: 70.001rem)");
  const desktop = useMediaQuery("(max-width: 90rem)");
  const desktop_small = useMediaQuery("(max-width: 70rem)");
  const tablet_landscape = useMediaQuery("(max-width: 64rem)");
  const tablet = useMediaQuery("(max-width: 48rem)");
  const mobile = useMediaQuery("(max-width: 30rem)");

  return {
    desktop_small_min,
    desktop_min,
    desktop,
    desktop_small,
    tablet_landscape,
    tablet,
    mobile,
  };
};
