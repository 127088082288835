import styles from "./Section.module.less";
import { LinkButton } from "../Button";

export const SectionLinkButton = ({ to, size = "md", variant = "text", children, ...rest }) => {
  return (
    <div className={styles.SectionDataGridButtonWrapper}>
      <LinkButton to={to} size={size} variant={variant} autoWidth {...rest}>
        {children}
      </LinkButton>
    </div>
  );
};
