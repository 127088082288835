import clsx from "clsx";
import { LinkButton } from "../Button";
import Image from "next/image";
import styles from "./ResourceCard.module.less";
import { SectionHeading, SectionParagraph } from "../Section";
import { FiTv } from "react-icons/fi";

export const ResourceCard = ({
  image,
  title,
  summary,
  authorImage,
  authorName,
  published,
  href,
  type = "default",
  className,
}) => {
  return (
    <LinkButton
      className={clsx(
        styles.ResourceCard,
        type === "alternate" && styles.Alternate,
        type === "webinar" && styles.Webinar,
        className,
      )}
      noStyle
      key={title}
      href={href}
    >
      <article>
        {image && (
          <div className={styles.ResourceCardImage}>
            <Image src={image} alt="" layout="fill" unoptimized />
          </div>
        )}
        {type == "webinar" && (
          <div className={styles.ResourceCardWebinarImage}>
            <FiTv />
          </div>
        )}
        <div className={styles.ResourceCardContent}>
          <SectionHeading>{title}</SectionHeading>
          <SectionParagraph>{summary}</SectionParagraph>
        </div>
        {type === "default" && (
          <div className={styles.ResourceCardAuthor}>
            <div className={styles.ResourceCardAuthorAvatar}>
              {authorImage && (
                <Image
                  priority={false}
                  src={authorImage}
                  alt=""
                  title={authorName}
                  loading="lazy"
                  width="40"
                  height="40"
                />
              )}
            </div>
            <div>
              <span className={styles.ResourceCardAuthorName}>{authorName}</span>
              <span className={styles.ResourceCardDate}>{published}</span>
            </div>
          </div>
        )}
      </article>
    </LinkButton>
  );
};
