import styles from "./Section.module.less";
import clsx from "clsx";

export const SectionBigNumber = ({
  icon,
  iconColor = "teal-400",
  number,
  prefix,
  abbreviation,
  subtitle,
  description,
  background = "blue-1000",
  svg = "",
}) => {
  const backgroundClassName = background?.replace(/-/g, "_").toLowerCase();
  const iconColorClassName = iconColor?.replace(/-/g, "_").toLowerCase();
  const iconToShow = icon ? (
    typeof icon === "function" ? (
      icon()
    ) : typeof icon === "string" ? (
      <img src={icon} alt="" />
    ) : null
  ) : typeof icon === "object" ? (
    icon
  ) : null;

  return (
    <h3 className={clsx(styles.SectionBigNumber, styles[backgroundClassName])}>
      {iconToShow && (
        <div className={clsx(styles.SectionBigNumberIcon, styles[iconColorClassName])}>
          {iconToShow}
        </div>
      )}
      {svg && (
        <div className={clsx(styles.SectionBigNumberIcon, styles[iconColorClassName])}>{svg}</div>
      )}
      <div className={styles.SectionBigNumberTop}>
        {number && (
          <div className={styles.SectionBigNumberNumber}>
            {prefix && <span className={styles.SectionBigNumberAbbr}>{prefix}</span>}
            <span>{number}</span>
            {abbreviation && <span className={styles.SectionBigNumberAbbr}>{abbreviation}</span>}
          </div>
        )}
      </div>
      {subtitle && <div className={styles.SectionBigNumberSubTitle}>{subtitle}</div>}
      {description && <div className={styles.SectionBigNumberDescription}>{description}</div>}
    </h3>
  );
};
