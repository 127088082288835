import clsx from "clsx";
import styles from "./Section.module.less";
import { LinkButton, CtaLinkButton } from "../../components/Button";

export const Section = ({
  id,
  type = "one-col",
  reverse = false,
  overrideColumnReversal = false,
  reverseMobile = false,
  doubledPadding = false,
  removeTopPadding = false,
  removeBottomPadding = false,
  colWidthsEqual = false,
  overlayHero = false,
  background = "white",
  masthead,
  className,
  children,
  classSectionWrapper = false,
}) => {
  const backgroundClassName =
    typeof background === "string"
      ? background.replace(/-/g, "_").toLowerCase()
      : typeof background === "object"
      ? background.map(bg => bg.replace(/-/g, "_").toLowerCase()).join("_to_")
      : null;

  return (
    <section
      id={id}
      className={clsx(
        styles.Section,
        styles[backgroundClassName],
        doubledPadding && styles.DoubledPadding,
        removeTopPadding && styles.RemoveTopPadding,
        removeBottomPadding && styles.RemoveBottomPadding,
        type === "one-col" && styles.TypeOneCol,
        type === "two-col" && styles.TypeTwoCol,
        type === "three-col" && styles.TypeThreeCol,
        reverse && styles.Reverse,
        reverseMobile && styles.ReverseMobile,
        overrideColumnReversal && styles.OverrideReversal,
        colWidthsEqual && styles.ColWidthsEqual,
        overlayHero && styles.OverlayHero,
        className,
      )}
    >
      <div className={styles.SectionContainer}>
        {masthead && (
          <div className={styles.SectionMasthead}>
            {masthead?.title && <h2 className={styles.SectionTitle}>{masthead.title}</h2>}
            {masthead?.subtitle &&
              (typeof masthead?.subtitle === "object" ? (
                masthead?.subtitle.map(subtitle => (
                  <p key={subtitle} className={styles.SectionSubtitle}>
                    {subtitle}
                  </p>
                ))
              ) : (
                <p className={styles.SectionSubtitle}>{masthead.subtitle}</p>
              ))}
            {masthead?.linkButton && (
              <LinkButton
                autoWidth={masthead?.linkButton?.autoWidth}
                variant={masthead?.linkButton?.variant}
                href={masthead?.linkButton?.to}
              >
                {masthead?.linkButton?.label}
              </LinkButton>
            )}
            {masthead?.ctaButton && <CtaLinkButton autoWidth />}
          </div>
        )}
        {children && (
          <div
            className={clsx(
              styles.SectionColWrapper,
              reverse && styles.Reverse,
              classSectionWrapper,
            )}
          >
            {children}
          </div>
        )}
      </div>
    </section>
  );
};
