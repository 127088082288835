import clsx from "clsx";
import styles from "./Hero.module.less";

export const HeroSubtitle = ({ noMarginBottom = false, children, className }) => {
  return (
    <p className={clsx(styles.HeroSubtitle, className, noMarginBottom && styles.NoMarginBottom)}>
      {children}
    </p>
  );
};
