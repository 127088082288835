import { useEffect, useState } from "react";
import butter from "../../utils/butter-client";
import { format } from "date-fns";
import styles from "./Section.module.less";
import { linksConstants } from "../../../constants/linksConstants";
import { ResourceCard } from "../subcomponents/ResourceCard";

export const SectionWebinarThumbnails = ({ type = "webinar", webinars, defaultPageSize = 3 }) => {
  const [thumbs, setThumbs] = useState([]);
  useEffect(() => {
    if (!webinars) {
      butter.page.retrieve("*", "webinars").then(response => {
        let data = response.data.data.fields.webinars;
        data = data
          .sort((a, b) => {
            let dateA = new Date(a.date);
            let dateB = new Date(b.date);
            if (dateA < dateB) return 1;
            if (dateA > dateB) return -1;
            return 0;
          })
          .filter(a => new Date(a.date) < new Date())
          .slice(1, 1 + defaultPageSize);
        setThumbs(data);
      });
    }
  }, []);

  return (
    <div className={styles.SectionWebinarThumbnails}>
      {thumbs?.map(({ title, video_actual, date }) => (
        <ResourceCard
          key={title}
          type={type}
          href={`${linksConstants.WEBINARS_VIDEO_SLUGIFIED.split("/:")[0]}/${
            video_actual.split("/").reverse()[0]
          }`}
          title={title}
          summary={format(new Date(date), "LLLL dd, yyyy")}
        />
      ))}
    </div>
  );
};
