import { useEffect, useState } from "react";
import butter from "../../utils/butter-client";
import clsx from "clsx";
import styles from "./Section.module.less";
import { linksConstants } from "../../../constants/linksConstants";
import { ResourceCard } from "../subcomponents/ResourceCard";

export const SectionBlogThumbnails = ({ type = "default", posts, defaultPageSize = 3 }) => {
  const [thumbs, setThumbs] = useState([]);

  useEffect(() => {
    if (!posts) {
      butter.post.list({ page: 1, page_size: defaultPageSize }).then(response => {
        const { data } = response.data;
        setThumbs(data);
      });
    }
  }, []);

  useEffect(() => {
    if (posts) {
      const getSpecificPosts = async () => {
        const specificPosts = await Promise.all(
          posts.map(async p => {
            const {
              data: { data },
            } = await butter.post.retrieve(p);

            return data;
          }),
        );

        setThumbs(specificPosts);
      };

      getSpecificPosts();
    }
  }, [posts]);

  if (!thumbs) return null;

  return (
    <div
      className={clsx(
        styles.SectionBlogThumbnails,
        type === "alternate" && styles.SectionBlogThumbnailsAlternate,
      )}
    >
      {thumbs?.map(({ featured_image, title, summary, author, slug }) => (
        <ResourceCard
          key={title}
          type={type}
          href={linksConstants.BLOGPOST.replace(":id", slug)}
          image={featured_image}
          title={title}
          summary={summary}
          authorImage={author.profile_image}
          authorName={`${author.first_name} ${author.last_name}`}
        />
      ))}
    </div>
  );
};
