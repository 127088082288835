import Image from "next/image";
import styles from "./Hero.module.less";
import { useViewport } from "../../../platform/utils/useViewport";
import clsx from "clsx";

export const HeroImage = ({ src, alt = "", className, tabletSrc, mobileSrc, ...props }) => {
  const { desktop, tablet, mobile } = useViewport();

  const deviceSpecificProps = {
    ...(desktop && {
      priority: true,
      src: src,
    }),
    ...(tablet &&
      tabletSrc && {
        src: tabletSrc,
      }),
    ...(mobile &&
      mobileSrc && {
        src: mobileSrc,
      }),
  };

  return (
    <div className={clsx(styles.HeroImage, className)}>
      <Image src={src} alt={alt} {...deviceSpecificProps} {...props} />
    </div>
  );
};
