import styles from "./Section.module.less";
import clsx from "clsx";

export const SectionHeading = ({ className, children, type = "h3", asParagraph = false }) => {
  if (!asParagraph) {
    if (type === "h2")
      return <h2 className={clsx(styles.SectionHeading, className)}>{children}</h2>;
    if (type === "h3")
      return <h3 className={clsx(styles.SectionHeading, className)}>{children}</h3>;
    if (type === "h4")
      return <h4 className={clsx(styles.SectionHeading, className)}>{children}</h4>;
    if (type === "h5")
      return <h5 className={clsx(styles.SectionHeading, className)}>{children}</h5>;
    if (type === "h6")
      return <h6 className={clsx(styles.SectionHeading, className)}>{children}</h6>;
    if (type === "p") return <p className={clsx(styles.SectionHeading, className)}>{children}</p>;
    if (type === "span")
      return <span className={clsx(styles.SectionHeading, className)}>{children}</span>;
  }

  return <p className={clsx(styles.SectionHeading, styles[type], className)}>{children}</p>;
};
